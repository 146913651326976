<template>
  <div>
    <div class="custom-container">
      <b-row>
        <b-col sm="12">
          <card class="p-0">
            <template class="p-0" v-slot:headerTitle>
              <div class="d-flex flex-column align-items-start">
                <h3 class="card-title p-0">
                  <b>Mis Honorarios</b>
                </h3>
              </div>
            </template>
            <div class="row m-3">
              <div class="col-2 px-0">
                <h4 class="mb-2"><b>Mes:</b></h4>
                <b-select v-model="mesSeleccionado" class="form-control bg-dark personalizado" :options="listaMes" text-field="mes" value-field="id" />
              </div>
              <div class="col-1 px-1">
                <h4 class="mb-2"><b>Año:</b></h4>
                <b-select v-model="anioSeleccionado" class="form-control bg-dark personalizado" :options="listaAnios" />
              </div>
              <div class="col-1 px-0 mt-3">
                <vs-button primary class="px-2 py-1" @click="filtrar(1)" id="sendform2">
                  <i class="fas fa-search mr-1"></i>
                </vs-button>
              </div>
              <div class="col px-1">
                <h4 class="mb-2"><b>Filtrar x profesional:</b></h4>
                <v-select
                  v-on:change="obtenerSucursales"
                  required
                  class="style-chooser"
                  :reduce="(value) => value"
                  v-model="profesional"
                  :options="listaProfesional"
                  label="nombreProfesional"
                  placeholder="-- selecciona un profesional --"
                ></v-select>
              </div>

              <div class="col-1 px-1 mt-3">
                <vs-button primary class="px-2 py-1" @click="filtrar(2)" id="sendform2">
                  <i class="fas fa-search mr-1"></i>
                </vs-button>
              </div>
            </div>

            <div class="mx-3">
              <b-button v-if="profesional" variant="info" class="float-left mt-3 mr-3" @click="verResumen()">Ver Resumen</b-button>
            </div>

            <div class="px-3 pt-3">
              <div class="text-center py-2">
                <label class="h5"
                  ><b>{{ profesional != null ? profesional.nombreProfesional : "" }}</b>
                </label>
              </div>
              <!-- Ttos. realizados, cancelados y evolucionados	 -->
              <div>
                <div class="m-3">
                  <h4><b>Ttos. realizados, cancelados y evolucionados</b></h4>
                </div>
                <table id="datatable_ttos" class="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th>Identificación Paciente</th>
                      <th>Paciente nombre</th>
                      <th>Concepto</th>
                      <th>Descripción</th>
                      <th>Tipo</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in listattos" :key="item.id">
                      <td>
                        {{ item.rut }}
                      </td>
                      <td>
                        {{ item.pacienteNombre }}
                      </td>
                      <td>
                        {{ item.concepto }}
                      </td>
                      <td>
                        {{ item.descripcion }}
                      </td>
                      <td>
                        {{ item.tipo }}
                      </td>
                      <td>
                        {{ item.valor.toFixed(0) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Costos material especial	 -->
              <div>
                <div class="m-3">
                  <h4><b>Costos material especial</b></h4>
                </div>
                <table id="datatable_ttos" class="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th>Identificación Paciente</th>
                      <th>Paciente nombre</th>
                      <th>Concepto</th>
                      <th>Descripción</th>
                      <th>Tipo</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in listaItemsMaterial" :key="item.id">
                      <td>
                        {{ item.rut }}
                      </td>
                      <td>
                        {{ item.pacienteNombre }}
                      </td>
                      <td>
                        {{ item.concepto }}
                      </td>
                      <td>
                        {{ item.descripcion }}
                      </td>
                      <td>
                        {{ item.tipo }}
                      </td>
                      <td>
                        {{ item.valor.toFixed(0) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Costos de remisión	 -->
              <div>
                <div class="m-3">
                  <h4><b>Costos de remisión</b></h4>
                </div>
                <table id="datatable_ttos" class="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th>Identificación Paciente</th>
                      <th>Paciente nombre</th>
                      <th>Concepto</th>
                      <th>Descripción</th>
                      <th>Tipo</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in listaItemsRemision" :key="item.id">
                      <td>
                        {{ item.rut }}
                      </td>
                      <td>
                        {{ item.pacienteNombre }}
                      </td>
                      <td>
                        {{ item.concepto }}
                      </td>
                      <td>
                        {{ item.descripcion }}
                      </td>
                      <td>
                        {{ item.tipo }}
                      </td>
                      <td>
                        {{ item.valor.toFixed(0) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="m-3">
                <h4><b>Otros conceptos</b></h4>
              </div>
              <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                <thead>
                  <tr>
                    <th>Identificación Paciente</th>
                    <th>Paciente nombre</th>
                    <th>Concepto</th>
                    <th>Descripción</th>
                    <th>Tipo</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in otrosConceptos" :key="item.id">
                    <td>
                      {{ item.rut }}
                    </td>
                    <td>
                      {{ item.pacienteNombre }}
                    </td>
                    <td>
                      {{ item.concepto }}
                    </td>
                    <td>
                      {{ item.descripcion }}
                    </td>
                    <td>
                      {{ item.tipo }}
                    </td>
                    <td>
                      {{ item.valor.toFixed(0) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </card>
        </b-col>
      </b-row>

      <b-modal no-enforce-focus :title="'Resumen: ' + cadenaMes() + ' ' + anioSeleccionado" id="resumen" size="lg" hide-footer body-class="body-modal-custom">
        <div class="p-2 mb-3 text-center">
          <span class="h5"> {{ profesional != null ? profesional.nombreProfesional : "" }} </span>
        </div>

        <div class="row px-3">
          <div class="col-6 px-1">
            <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Ttos. realizados, cancelados y evol. :</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ totalTtosRealizados.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6 px-1">
            <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Costos material especial</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ totalCostosMaterial.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6 px-1">
            <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Costos de remisión</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ totalCostosRemision.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6 px-1">
            <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Costos de laboratorio</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ totalCostosLaboratorio.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-6 px-1">
            <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Total agregados</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ totalAgregados.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6 px-1">
            <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Total descuentos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ totalDescuentos.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 px-1">
            <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>valor neto honorarios</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ totalNetoHonorarios.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { core } from "../../../../config/pluginInit";
// import moment_timezone from "moment-timezone";
// import CardTable from "../../../../components/cardTable/CardTable.vue";
// import CardTable from "@/components/cardTable/CardTableV2.vue";
// import CardTable from "@/components/cardTable/CardTableV3.vue";
import * as XLSX from "xlsx";

// import BtnAddCardTable from "../../../../components/btnCardTable/btnAddCardTable.vue";

// const Swal = require("sweetalert2");
export default {
  name: "registroServicios",
  components: {
    // CardTable,
    // BtnAddCardTable,
  },
  data() {
    return {
      listaMes: [
        { id: 1, mes: "Enero" },
        { id: 2, mes: "Febrero" },
        { id: 3, mes: "Marzo" },
        { id: 4, mes: "Abril" },
        { id: 5, mes: "Mayo" },
        { id: 6, mes: "Junio" },
        { id: 7, mes: "Julio" },
        { id: 8, mes: "Agosto" },
        { id: 9, mes: "Septiembre" },
        { id: 10, mes: "Octubre" },
        { id: 11, mes: "Noviembre" },
        { id: 12, mes: "Diciembre" },
      ],
      listaAnios: [],
      mesSeleccionado: null,
      anioSeleccionado: null,
      persona: {},
      dataTableInstance: null,
      listaPacientes: [],
      listaProfesional: [],
      listaSucursal: [],
      listaItems: [],
      listaItemsbk: [],

      paciente: null,
      profesional: null,
      sucursal: null,
      listaItemsMaterial: [],
      listaLaboratorio: [],
      listaLaboratorioBk: [],
      listaItemsRemision: [],
      listattos: [],
      Registro: {},
      Periodo: {},
      otrosConceptos: [],
      pacienteManual: "",
      totalAgregados: 0,
      totalDescuentos: 0,
      totalTtosRealizados: 0,
      totalCostosMaterial: 0,
      totalCostosRemision: 0,
      totalCostosLaboratorio: 0,
      totalNetoHonorarios: 0,

      activaTablas: false,
    };
  },
  async mounted() {
    const anioActual = new Date().getFullYear();
    const anioInicio = anioActual - 2;
    const listaAnios = [];

    for (let anio = anioInicio; anio <= anioActual; anio++) {
      listaAnios.push(anio);
    }
    this.listaAnios = listaAnios;
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.listaItems = [];
    this.mesSeleccionado = new Date().getMonth();
    this.anioSeleccionado = new Date().getFullYear();
    this.Periodo = this.obtenerPrimerYUltimoDiaDelMes(this.anioSeleccionado, this.mesSeleccionado);
    if (window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
      window.$("#datatable_registroServicios").DataTable().destroy();
    }

    core.index();
  },
  methods: {
    cadenaMes() {
      let numero = this.mesSeleccionado;
      const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

      // Verificar que el número esté en el rango válido (1-12)
      if (numero < 1 || numero > 12) {
        return "Número inválido. Debe estar entre 1 y 12.";
      }

      // Restar 1 para obtener el índice correcto del mes
      return meses[numero - 1];
    },
    obtenerSucursales() {
      alert("asd");
    },
    verResumen() {
      this.$bvModal.show("resumen");
    },
    showToast(type, message) {
      console.log(type);
      if (type == "success") {
        this.$toast.success(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (type == "error")
        this.$toast.error(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      if (type == "warning")
        this.$toast.warning(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
    },

    verModal() {
      this.$bvModal.show("nuevoServicio");
    },

    exportToExcel() {
      // Crear las hojas de datos de cada array con títulos y sumatorias
      const sheet1 = this.createSheetWithSum(this.listaItems, "Prestaciones", "valorPrestacion");
      const sheet2 = this.createSheetWithSum(this.listaItemsMaterial, "Material Especial", "valor");
      const sheet3 = this.createSheetWithSum(this.listaLaboratorio, "Costos Laboratorio", "valorPrestacion");

      // Crear un nuevo libro de trabajo
      const wb = XLSX.utils.book_new();

      // Agregar cada hoja de trabajo con el nombre deseado
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet1), "Prestaciones");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet2), "Material Especial");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet3), "Costos Laboratorio");

      // Exportar el archivo Excel
      // XLSX.writeFile(wb, 'datos_separados.xlsx');
      XLSX.writeFile(wb, "Liquidación-" + this.profesional + "-" + this.mesSeleccionado + "-" + this.anioSeleccionado + "-" + this.sucursal + ".xlsx");
    },

    // Función para crear una hoja con sumatoria
    createSheetWithSum(data, title, sumColumn) {
      // Verificar si el array de datos está vacío
      if (!data || data.length === 0) {
        console.warn(`El array ${title} está vacío o no está definido.`);
        return [[title], ["No hay datos disponibles"]]; // Retorna un mensaje si el array está vacío
      }

      const headers = Object.keys(data[0]); // Obtener encabezados de las claves del array
      const sheetData = [];

      // Agregar los encabezados de las columnas
      sheetData.push(headers);

      // Agregar los datos del array
      data.forEach((item) => {
        sheetData.push(headers.map((key) => item[key]));
      });

      // Calcular y agregar la fila de sumatoria
      const sumRow = headers.map((key) => (key === sumColumn ? this.sumColumn(data, key) : ""));
      sheetData.push(["Total", ...sumRow.slice(1)]); // Agregar 'Total' y la sumatoria

      return sheetData;
    },

    // Función para calcular la sumatoria de una columna específica
    sumColumn(data, column) {
      return data.reduce((sum, item) => {
        const value = Number(item[column]); // Convertir a número
        return sum + (isNaN(value) ? 0 : value); // Sumar solo si es un número válido
      }, 0);
    },

    async filtrar(item) {
      this.otrosConceptos = [];
      this.listaItems = [];
      if (item == 1) {
        this.profesional = null;
        this.sucursal = null;
        ////////
        this.$isLoading(true);
        //filtrar x mes y año
        let mesSeleccionado = new Date(this.anioSeleccionado + "/" + this.mesSeleccionado + "/01").getMonth();
        let anioSeleccionado = this.anioSeleccionado; //new Date().getFullYear();
        this.Periodo = this.obtenerPrimerYUltimoDiaDelMes(anioSeleccionado, mesSeleccionado);
        await this.getData(this.Periodo.primerDia, this.Periodo.ultimoDia);

        this.$isLoading(false);
      }
      if (item == 2) {
        //problema: no existe rut del profesional en prestaciones
        try {
          this.$isLoading(true);

          //filtrando materiales si ha seleccionado profesional y sucursal
          let mesSeleccionado = new Date(this.anioSeleccionado + "/" + this.mesSeleccionado + "/01").getMonth();
          let anioSeleccionado = this.anioSeleccionado; //new Date().getFullYear();
          this.Periodo = this.obtenerPrimerYUltimoDiaDelMes(anioSeleccionado, mesSeleccionado);
          console.log("periodo", this.Periodo);
          await this.obtenerData(this.Periodo.primerDia, this.Periodo.ultimoDia);
          // obtener otros conceptos
          await this.obtenerOtrosConceptos(this.Periodo.primerDia, this.Periodo.ultimoDia);
          this.listattos = this.listaItems.filter((x) => x.concepto == "Ttos. realizados, cancelados y evolucionados");
          this.listaItemsMaterial = this.listaItems.filter((x) => x.concepto == "Costos material especial");
          this.listaItemsRemision = this.listaItems.filter((x) => x.concepto == "Costos de remisión");

          this.listaLaboratorio = this.listaItems.filter((x) => x.concepto == "Costos Laboratorio");

          // totalizando datos

          this.totalAgregados = 0;
          this.totalDescuentos = 0;
          // this.otrosConceptos.forEach((item) => {
          //   if (item.tipo === "Descuento") {
          //     this.totalDescuentos += item.valor;
          //   }
          //   // else if (item.tipo === "Agregado") {
          //   //   this.totalAgregados += item.valor;
          //   // }
          // });
          //// CALCULANDO AGREGADOS Y DESCUENTOS DE OTROS CONCEPTOS
          let listaAgregados = this.otrosConceptos.filter((x) => x.tipo != "Descuento");
          let totalAgregados = listaAgregados.reduce((accumulator, currentItem) => {
            const valueToAdd = parseFloat(currentItem.valor);
            return accumulator + valueToAdd;
          }, 0);
          //this.totalAgregados = totalAgregados;

          let listaDescuentos = this.otrosConceptos.filter((x) => x.tipo == "Descuento");
          let totalDescuentos = listaDescuentos.reduce((accumulator, currentItem) => {
            const valueToAdd = parseFloat(currentItem.valor);
            return accumulator + valueToAdd;
          }, 0);
          //this.totalDescuentos = totalDescuentos;

          //CALCULANDO AGREGADOS Y DESCUENTOS DE LISTAITEMS
          let listaAgregadosttos = this.listaItems.filter((x) => x.tipo != "Descuento");
          let totalAgregadosttos = listaAgregadosttos.reduce((accumulator, currentItem) => {
            const valueToAdd = parseFloat(currentItem.valor);
            return accumulator + valueToAdd;
          }, 0);

          let listaDescuentosttos = this.listaItems.filter((x) => x.tipo == "Descuento");
          let totalDescuentosttos = listaDescuentosttos.reduce((accumulator, currentItem) => {
            const valueToAdd = parseFloat(currentItem.valor);
            return accumulator + valueToAdd;
          }, 0);

          this.totalDescuentos = Number(totalDescuentosttos) + Number(totalDescuentos);
          this.totalAgregados = Number(totalAgregadosttos) + Number(totalAgregados);
          this.totalNetoHonorarios = Number(this.totalAgregados) - Number(this.totalDescuentos);

          ///calculos totales///////////////////////////////////////////

          this.totalTtosRealizados = this.listaItems.reduce((accumulator, currentItem) => {
            const valueToAdd = Number(currentItem.porcentaje) > 0 ? parseFloat(currentItem.valorPrestacionSub) : parseFloat(currentItem.valor);
            return accumulator + valueToAdd;
          }, 0);

          this.totalCostosMaterial = this.listaItemsMaterial.reduce((accumulator, currentItem) => {
            const valueToAdd = parseFloat(currentItem.valor);
            return accumulator + valueToAdd;
          }, 0);

          this.totalCostosRemision = this.listaItemsRemision.reduce((accumulator, currentItem) => {
            const valueToAdd = parseFloat(currentItem.valor);
            return accumulator + valueToAdd;
          }, 0);

          this.totalCostosLaboratorio = this.listaLaboratorio.reduce((accumulator, currentItem) => {
            const valueToAdd = parseFloat(currentItem.valor);
            return accumulator + valueToAdd;
          }, 0);
          // let sumatorias = parseFloat(this.listattos) + parseFloat(this.totalAgregados);
          // let descuentos = parseFloat(this.totalCostosLaboratorio) + parseFloat(this.totalCostosRemision) + parseFloat(this.totalDescuentos);

          //this.totalNetoHonorarios = ; //sumatorias - descuentos;

          this.$forceUpdate();
          this.$isLoading(false);
        } catch (error) {
          this.$isLoading(false);
        }
        //console.log("profesional data", this);
        // idProfesional = idProfesional[0].rut;
        // console.log("profesional data", idProfesional);
      }
    },
    async obtenerData(primerDia, ultimoDia) {
      console.log("proff", this.profesional.nombreProfesional);
      this.listaItems = [];
      let res = await this.$store.dispatch("denti_get", {
        path: "Prestaciones/MiHonorarioPorProfesionalFecha/" + this.profesional.nombreProfesional + "/" + primerDia + "/" + ultimoDia,
      });
      console.log("otros", res);
      this.listaItems = res;
    },
    async obtenerOtrosConceptos(primerDia, ultimoDia) {
      this.otrosConceptos = [];
      let res = await this.$store.dispatch("denti_get", {
        path: "Prestaciones/ListaOtrosConceptosPorProfesional/" + primerDia + "/" + ultimoDia + "/" + this.profesional.nombreProfesional + "/",
      });
      console.log("otros", res);
      this.otrosConceptos = res;
    },

    obtenerPrimerYUltimoDiaDelMes(anio, mes) {
      // El mes en JavaScript es base 0, por lo que enero es 0 y diciembre es 11
      const primerDia = new Date(anio, mes, 1);
      const ultimoDia = new Date(anio, mes + 1, 0); // El día 0 del mes siguiente es el último día del mes actual

      return {
        primerDia: primerDia.toISOString().split("T")[0], // Formato YYYY-MM-DD
        ultimoDia: ultimoDia.toISOString().split("T")[0],
      };
    },
    async getData(primerDia, ultimoDia) {
      try {
        if (window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
          window.$("#datatable_registroServicios").DataTable().destroy();
        }
        this.listaItems = [];
        // console.log("getdata dentisalud", Periodo);
        let res = await this.$store.dispatch("denti_get", {
          path: "Prestaciones/ListaProfesionalesPorPeriodo/" + primerDia + "/" + ultimoDia,
        });
        console.log("res dentisalud ", res);

        this.listaProfesional = res.map((x) => ({
          // console.log(x.profesional.nombreProfesional);
          nombreProfesional: x.profesional.nombreProfesional,
        }));

        // this.listaSucursal = res.map((pa) => pa.sucursalPpto).filter((nombrep, indexp, selfp) => selfp.indexOf(nombrep) === indexp);
        // this.listaSucursal.sort((a, b) => {
        //   if (a < b) return -1;
        //   if (a > b) return 1;
        //   return 0;
        // });

        // console.log("pacientes ", this.listaPacientes);
        // console.log("list registroServicios...", res);
        // if (res.success) {
        //   this.listaItems = res.data.map((service) => {
        //     return {
        //       ...service,
        //       placa: this.getPlacaVehiculo(service.placaId),
        //       estado: service.fechaFin ? true : false,
        //     };
        //   });
        //   // console.log(this.listaItems);
        // } else {
        //   this.listaItems = [];
        // }
        this.$isLoading(false);

        // this.$nextTick(function () {
        //   if (!window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
        //     window.$("#datatable_registroServicios").DataTable();
        //   }
        // });

        // core.index();

        // if (window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
        //   window.$("#datatable_registroServicios").DataTable().destroy();
        // }
        // this.dataTableInstance = window.$("#datatable_registroServicios").DataTable({
        //   language: {
        //     url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        //   },
        // });
        // this.$forceUpdate();
      } catch (error) {
        this.$isLoading(false);

        this.listaItems = [];
        console.log("err dentisalud ", error);
      }
    },
  },
};
</script>

<style scoped>
.custom-popup {
  background-color: #f0f8ff;
  border-radius: 10px;
  padding: 1em;
  font-size: 1rem !important;
}

.custom-title {
  color: #333;
  font-size: 0.5rem !important;
  font-weight: bold;
}

.custom-input {
  border: 1px solid #ddd;
  padding: 0.5em;
  border-radius: 5px;
}

.custom-confirm-button {
  background-color: #4caf50;
  color: white;
  padding: 0.5em 1.5em;
}

.custom-cancel-button {
  background-color: #f44336;
  color: white;
}
</style>
